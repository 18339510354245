/**
 * Format all pageContext data
 */
import { getThemeData } from './theme-data';

export function getContextData(context, data) {
  const { id } = context;
  const { defaultLocale } = context;
  const { locale } = context;
  const { locales } = context;
  const { postType } = context;
  const title = data.pageData ? (data.pageData.title ? data.pageData.title : (data.pageData.shortName ? data.pageData.shortName : null)) : null;
  const { slug } = context;
  const { strapiId } = context;
  const pageDataId = data.pageData?.pageDataId;
  const localizations = {
    data: [],
  };
  data?.pageData?.localizations?.forEach((localization) => {
    const formatedLocale = {
      attributes: {
        ...localization,
      },
    };
    localizations.data.push(formatedLocale);
  });

  const dataLayer = {
    ...context.dataLayer,
    newsletter: {
      ...context.dataLayer?.newsletter,
      page: pageDataId,
    },
  };

  const theme = getThemeData(context.siteTheme, context.globalData.navigation.edges[0].node);

  const buyModalTitle = context.globalData?.navigation?.edges?.[0]?.node?.ctaLinks?.[0]?.title;
  const buyModalSubTitle = context.globalData?.navigation?.edges?.[0]?.node?.ctaLinks?.[0]?.subtitle;

  // Get page's default title (English title for tracking uniformity)
  let defaultLocaleTitle = title;
  if (data.pageData && data.pageData.localizations && data.pageData.localizations.data && data.pageData.localizations.data.length > 0) {
    const defaultLocalization = data.pageData.localizations.data.find((e) => e.attributes.locale === defaultLocale);
    defaultLocaleTitle = defaultLocalization && defaultLocalization.attributes ? defaultLocalization.attributes.title : title;
  }

  // Generate a cleaner pageContext
  const contextData = { id, dataLayer, defaultLocale, defaultLocaleTitle, locale, locales, localizations, postType, theme, title, buyModalTitle, buyModalSubTitle, slug, strapiId, pageDataId };
  return contextData;
}
