import React from 'react';
import { AnimatePresence } from 'framer-motion';

import LoaderContextProvider from '@base/utils/contexts/loader-context';
import Layout from '@base/layout/layout';

import Loader from '@elements/loader';
import CustomNewsletterContent from '@elements/custom-newsletter-content';
import CustomNewsletterForm from '@elements/custom-newsletter-form';
import CustomFooterContent from '@elements/custom-footer-content';
import { getContextData } from '@api/context-data';
import { getAllData } from '@api/all-data';
import GateContextProvider from '@/utils/contexts/gate-context';

const themeStyle = require('@base/assets/styles/global.scss');
const globalStyle = require('@assets/styles/global.scss');

export const wrapPageElement = ({ element, props }) => {
  const browser = typeof window !== 'undefined' && window;
  if (!props || !element || !browser) {
    return null;
  }

  let pageData = props?.data;
  if (!props.data || !props.data.pageData) {
    pageData = { pageData: props?.pageContext?.content };
  }
  if (!pageData || !pageData.pageData) {
    return element;
  }

  // Setup Global website data (data that will be used in layout components like header and footer)
  const contextData = getContextData(
    props.pageContext,
    props?.data || pageData,
  );
  const generalData = getAllData(props.pageContext.globalData, pageData);

  // Page name value for GA4 tracking
  const trackingPageName = contextData.pageDataId
    || (contextData.postType === 'error' && 'error_page')
    || contextData.title;

  return (
    <GateContextProvider>
      <LoaderContextProvider>
        <Layout
          data={pageData}
          globalData={generalData}
          pageContext={contextData}
          dataLayer={{ ...contextData.dataLayer, trackingPageName }}
          customNewsletterForm={CustomNewsletterForm}
          customNewsletterContent={CustomNewsletterContent}
          customFooterContent={CustomFooterContent}
        >
          <AnimatePresence mode='wait'>{element}</AnimatePresence>
        </Layout>
        <Loader pageContext={props.pageContext} />
      </LoaderContextProvider>
    </GateContextProvider>
  );
};

// Scroll Update on Route change
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  window.setTimeout(() => window.scrollTo(0, 0), 100);
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), 100);
  } else {
    // if we used the browser's forwards or back button
    const savedPosition = getSavedScrollPosition(location) || [0, 0];
    window.setTimeout(() => window.scrollTo(...savedPosition), 100);
  }
  return false;
};
