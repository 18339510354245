module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://thecastingoffrankstone.com","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Casting of Frank Stone","short_name":"The Casting of Frank Stone","lang":"en","start_url":"/","background_color":"#000414","theme_color":"#000414","display":"minimal-ui","icon":"./static/favicon.png","legacy":false,"include_favicon":false,"localize":[{"start_url":"/de/","lang":"de","name":"The Casting of Frank Stone","short_name":"The Casting of Frank Stone"},{"start_url":"/fr/","lang":"fr","name":"The Casting of Frank Stone","short_name":"The Casting of Frank Stone"},{"start_url":"/ja/","lang":"ja","name":"The Casting of Frank Stone","short_name":"The Casting of Frank Stone"},{"start_url":"/es/","lang":"es","name":"The Casting of Frank Stone","short_name":"The Casting of Frank Stone"},{"start_url":"/pt/","lang":"pt","name":"The Casting of Frank Stone","short_name":"The Casting of Frank Stone"}],"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"ff638fe7d7999f9f6c9ed35c9c913da7"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
