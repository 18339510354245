import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useInView } from 'react-intersection-observer';

function CustomFooterContent() {
  const { ref, inView } = useInView({
    threshold: 0.25,
    triggerOnce: true,
  });

  const data = useStaticQuery(graphql`
    query footerBgQuery {
      file(relativePath: {glob: "backgrounds/footer-background*"}) {
        name
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  return (
    <div ref={ref} className='footer-background'>
      {inView && (
        <GatsbyImage
          image={data.file.childImageSharp.gatsbyImageData}
          className='gatsby-absolute pointer-events-none left-0 top-0 h-full w-full'
          placeholder='blurred'
          role='presentation'
        />
      )}
    </div>
  );
}

export default CustomFooterContent;
