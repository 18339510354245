/**
 * Format Age Gate Related data
 */

export function getAgeGateData(data, pageData) {
  const defaultAgeGateData = data.defaultGlobal?.ageGate;
  const localeAgeGateData = data.global?.edges?.[0]?.node?.ageGate;
  const ageGate = {
    ...defaultAgeGateData,
    ...localeAgeGateData,
  };

  const backgroundImage = ageGate?.backgroundImage ? ageGate.backgroundImage : null;
  const buttonLabel = ageGate?.buttonLabel ? ageGate.buttonLabel : 'Submit';
  const cookieName = ageGate?.cookieName ? ageGate.cookieName : 'bhvr-age-gate';
  const siteEnabled = ageGate?.enabled ? ageGate.enabled : false; // Disable the age gate globally by default
  const pageEnabled = !pageData?.disableAgeGate; // Enable full-page age gating
  const errorMessage = ageGate?.errorMessage ? ageGate.errorMessage : null;
  const errorTitle = ageGate?.errorTitle ? ageGate.errorTitle : null;
  const title = ageGate?.title ? ageGate.title : null;
  const text = ageGate?.text ? ageGate.text : null;
  const logo = ageGate?.logo ? ageGate.logo : null;

  const ageGateData = {
    backgroundImage,
    buttonLabel,
    cookieName,
    siteEnabled,
    pageEnabled,
    errorMessage,
    errorTitle,
    title,
    text,
    logo,
    video: {
      classes: {
        modal: 'fixed top-0 left-0 w-full h-full flex justify-center items-center z-god',
        backdrop: 'absolute w-full h-full bg-black/80 md:bg-black/40 flex justify-center items-center right-0 :top-0',
        content: 'relative w-full md:w-[90%] lg:w-3/4 xxl:w-1/2 h-full lg:h-auto z-10 flex flex-col items-center justify-center p-14 lg:px-12 lg:py-48',
        form: 'flex flex-col lg:flex-row lg:mt-10 mb-0 items-center lg:items-end relative text-white font-body font-normal w-full lg:w-auto',
        input: 'relative mt-2 mb-0 w-full text-center lg:w-36 h-20 text-base font-body tracking-wider',
        submit: 'button inline-flex items-center justify-center relative z-10 lg disabled lg:ml-[16px] w-full md:w-auto',
        error: 'absolute top-8 inline-flex flex-row justify-between border border-solid border-red bg-black px-4 py-6 text-sm text-white',
      },
      cancellable: true,
      pageLock: true,
    },
    classes: {
      modal: 'fixed top-0 left-0 w-full h-full flex justify-center items-center z-god',
      backdrop: 'absolute w-full h-full bg-black/80 md:bg-black/40 flex justify-center items-center right-0 :top-0',
      content: 'relative w-full md:w-[90%] lg:w-3/4 xxl:w-1/2 h-full md:h-auto z-10 flex flex-col items-center justify-center p-14 lg:px-12 lg:py-48',
      form: 'flex flex-col lg:flex-row lg:mt-10 mb-0 items-center lg:items-end relative text-white font-body font-normal w-full lg:w-auto',
      input: 'relative mt-2 mb-0 w-full text-center lg:w-36 h-20 text-base font-body tracking-wider',
      submit: 'button inline-flex items-center justify-center relative z-10 lg disabled lg:ml-[16px] w-full md:w-auto',
      error: 'absolute top-8 inline-flex flex-row justify-between border border-solid border-red bg-black px-4 py-6 text-sm text-white',
    },
  };
  return ageGateData;
}
