import React, { useState } from 'react';
import SVG from 'react-inlinesvg';
import { StaticImage } from 'gatsby-plugin-image';
import { motion, AnimatePresence } from 'framer-motion';

import Newsletter from '@base/elements/newsletter';
import Heading from '@base/parts/heading';
import Button from '@base/parts/button';

import closeIcon from '@img/icons/close.svg';
import desktopIcon from '@img/icons/desktop.svg';
import wideIcon from '@img/icons/wide.svg';
import tabletIcon from '@img/icons/tablet.svg';
import mobileIcon from '@img/icons/mobile.svg';

import desktopScreen from '@img/wallpapers/keyart/tcofs-ew-wallpaper-1600x1200.jpg';
import desktop2kScreen from '@img/wallpapers/keyart/tcofs-ew-wallpaper-2560x1440.jpg';
import desktop4kScreen from '@img/wallpapers/keyart/tcofs-ew-wallpaper-3840x2160.jpg';

import wideScreen from '@img/wallpapers/keyart/tcofs-ew-wallpaper-3440x1440.jpg';
import wideBigScreen from '@img/wallpapers/keyart/tcofs-ew-wallpaper-2960x1050.jpg';
import wideBiggerScreen from '@img/wallpapers/keyart/tcofs-ew-wallpaper-1920x1200.jpg';

import tabletScreen from '@img/wallpapers/keyart/tcofs-ew-wallpaper-2560x1600.jpg';
import mobileScreen from '@img/wallpapers/keyart/tcofs-ew-wallpaper-1080x1920.jpg';
import mobileBigScreen from '@img/wallpapers/keyart/tcofs-ew-wallpaper-1440x2560.jpg';

function CrmForm({ globalData, pageContext, type }) {
  const [formSuccess, setFormSuccess] = useState(false);
  const error = globalData?.stringsErrors;
  const dates = globalData?.stringsDates;
  const theme = pageContext.theme.siteTheme.content.Newsletter;
  const newsletterFormStyles = theme && theme.Form ? theme.Form : undefined;
  const newsletterData = globalData?.newsletterData;
  const ageGateData = globalData?.ageGateData;

  const desktopData = [
    {
      text: newsletterData.desktop,
      icon: desktopIcon,
      size: '3840x2160',
      image: desktop4kScreen,
    },
    {
      text: newsletterData.desktop,
      icon: desktopIcon,
      size: '2560x1440',
      image: desktop2kScreen,
    },
    {
      text: newsletterData.desktop,
      icon: desktopIcon,
      size: '1600x1200',
      image: desktopScreen,
    },
  ];

  const wideData = [
    {
      text: newsletterData.wide,
      icon: wideIcon,
      size: '3440x1440',
      image: wideScreen,
    },
    {
      text: newsletterData.wide,
      icon: wideIcon,
      size: '2960x1050',
      image: wideBigScreen,
    },
    {
      text: newsletterData.wide,
      icon: wideIcon,
      size: '1920x1200',
      image: wideBiggerScreen,
    },
  ];

  const mobileData = [
    {
      text: newsletterData.mobile,
      icon: mobileIcon,
      size: '1080x1920',
      image: mobileScreen,
    },
    {
      text: newsletterData.mobile,
      icon: mobileIcon,
      size: '1440x2560',
      image: mobileBigScreen,
    },
  ];

  const tabletData = [
    {
      text: newsletterData.tablet,
      icon: tabletIcon,
      size: '2560x1600',
      image: tabletScreen,
    },
  ];

  const objectScreen = [desktopData, wideData, mobileData, tabletData];

  const formSuccessCallback = () => {
    setFormSuccess(true);
  };

  const fadeIn = {
    visible: {
      display: 'flex',
      opacity: 1,
    },
    hidden: {
      opacity: 0,
      transitionEnd: { display: 'none' },
    },
  };

  return (
    <AnimatePresence>
      <motion.div
        initial='hidden'
        animate={!formSuccess ? 'visible' : 'hidden'}
        exit='hidden'
        variants={fadeIn}
        transition={{ duration: 0.5 }}
        className={type === 'modal' ? 'modal-crm-content' : 'newsletter-content-wrapper'}
      >
        <>
          {type === 'modal' && (
            <div className='modal-crm-title'>
              {newsletterData?.customTitle && (
                <Heading
                  text={newsletterData.customTitle}
                  tag='h2'
                  className='h3'
                />
              )}
              {newsletterData?.subtitle?.text && (
                <Heading
                  text={newsletterData?.subtitle?.text}
                  tag='h2'
                  className='h3 mt-4 rotate-0'
                />
              )}
            </div>
          )}
          <Newsletter
            type={type}
            id={`${type}-crm-form`}
            pageContext={pageContext}
            strings={{ error, dates }}
            data={{ newsletterData, ageGateData }}
            styling={newsletterFormStyles}
            dataLayer={{
              ...pageContext?.dataLayer?.newsletter,
              section: type === 'modal' ? 'sticky tab' : 'footer',
            }}
            callback={() => formSuccessCallback()}
            list={pageContext.dataLayer?.game?.replace(/[' ']/g, '')}
            devMode={process.env.GATSBY_ENV === 'dev' && !process.env.GATSBY_DISABLE_DEVTOOLS}
            closeIcon={closeIcon}
          />
        </>
      </motion.div>
      <motion.div
        initial='hidden'
        animate={formSuccess ? 'visible' : 'hidden'}
        exit='hidden'
        variants={fadeIn}
        transition={{ duration: 0.5, delay: 0.5 }}
        className={type === 'modal' ? 'modal-crm-content' : 'newsletter-content-wrapper'}
      >
        {formSuccess && (
          <>
            <div className='modal-crm-success w-full'>
              <div className='modal-crm-title'>
                <Heading
                  text={newsletterData.successTitle}
                  tag='h2'
                  className='crm-modal-title h3'
                />
                <Heading
                  text={newsletterData.successSubTitle}
                  tag='h2'
                  className='crm-modal-subtitle h3 mt-4 rotate-0'
                />
              </div>
              <div className='modal-crm-success-content relative w-full'>
                <StaticImage
                  src='../../../assets/img/wallpapers/keyart/tcofs-ew-wallpaper-preview.jpg'
                  alt=''
                  placeholder='blurred'
                  loading='lazy'
                  className='image-success desktop'
                  role='presentation'
                />
                <div className='w-full'>
                  <p className='pb-8 text-xs md:text-sm'>
                    {newsletterData.successText}
                  </p>
                  <StaticImage
                    src='../../../assets/img/wallpapers/keyart/tcofs-ew-wallpaper-preview.jpg'
                    alt=''
                    placeholder='blurred'
                    loading='lazy'
                    className='image-success mobile'
                    role='presentation'
                  />
                  <p className='pb-8 pt-4 text-xs md:pt-0 md:text-sm'>
                    {newsletterData.successInfo}
                  </p>
                  <div className='modal-crm-success-btns'>
                    {/* Desktop */}
                    <div className='devices hidden flex-row justify-center space-x-4 md:flex lg:justify-between lg:space-x-8 xl:justify-start'>
                      {objectScreen.map((object, i) => (
                        <div className='flex flex-col items-center'>
                          <SVG
                            cacheRequests
                            src={object[0].icon}
                            className='pointer-events-none w-24'
                          />
                          <p className='pb-2 pt-4 text-center text-xs font-semibold md:text-s xl:text-sm'>
                            {object[0].text}
                          </p>
                          {object.map((element) => (
                            <LinksDesktop
                              data={element}
                              pageContext={pageContext}
                            />
                          ))}
                        </div>
                      ))}
                    </div>

                    {/* Mobile */}
                    <div className='flex flex-wrap md:hidden'>
                      {objectScreen.reverse().map((object, i) => (
                        <LinksMobile data={object} pageContext={pageContext} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </motion.div>
    </AnimatePresence>
  );
}

export default CrmForm;

function LinksDesktop({ data, pageContext }) {
  return (
    <Button
      link={data.image}
      title={`casting-of-frank-stone-screen-${data.size}`}
      trigger='download'
      pageContext={pageContext}
      className='modal-crm-success-btn button-tertiary no-icon'
      eventAction={`download_${data.size}`}
      eventPage={pageContext.pageDataId}
      trackable
    >
      <p className='mb-1 text-xs font-normal md:text-s'>{data.size}</p>
    </Button>
  );
}

function LinksMobile({ data, pageContext }) {
  return (
    <>
      {data.map((element, i) => (
        <div className='w-1/3 pb-8'>
          <Button
            link={element.image}
            title={`casting-of-frank-stone-screen-${element.size}`}
            trigger='download'
            pageContext={pageContext}
            className='modal-crm-success-btn button-tertiary mx-auto'
            eventAction={`download_${element.size}`}
            eventPage={pageContext.pageDataId}
            trackable
          >
            <SVG
              cacheRequests
              src={element.icon}
              className='pointer-events-none w-24'
            />
            <p className='pb-2 pt-4 text-xs font-semibold md:text-s xl:text-sm'>
              {element.text}
            </p>
            <p className='text-xs font-normal md:text-s xl:text-sm'>
              {element.size}
            </p>
          </Button>
        </div>
      ))}
    </>
  );
}
