import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useInView } from 'react-intersection-observer';

function CustomNewsletterContent() {
  const { ref, inView } = useInView({
    threshold: 0.25,
    triggerOnce: true,
  });

  const data = useStaticQuery(graphql`
    query newsletterBgQuery {
      allFile(
        filter: { relativePath: { glob: "backgrounds/newsletter-background*" } }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  return (
    <div ref={ref} className='newsletter-background absolute pointer-events-none left-0 top-0 h-full w-full'>
      {data.allFile?.edges?.map((file) => (
        inView && (
          <GatsbyImage
            image={file.node.childImageSharp.gatsbyImageData}
            className={` h-full w-full ${file.node.name === 'newsletter-background-mobile' ? 'gatsby-hidden-desktop' : 'gatsby-hidden-mobile'}`}
            placeholder='blurred'
            role='presentation'
          />
        )
      ))}
    </div>
  );
}

export default CustomNewsletterContent;
