import React, { useEffect, useContext } from 'react';

import { LoadingContext } from '@base/utils/contexts/loader-context';

function Loader(pageContext) {
  const { loading } = useContext(LoadingContext);
  const [isLoading, setLoading] = loading;

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [pageContext]);

  return (
    <div
      id='pageLoader'
      className='bg-black pointer-events-none fixed left-0 top-0 z-[9999999999] h-screen w-screen opacity-100 transition-all duration-200'
      style={{
        opacity: `${isLoading ? 1 : 0}`,
      }}
    />
  );
}

export default Loader;
