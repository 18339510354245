import React, { createContext, useState } from 'react';

const defaultState = {
  isFirstTime: false,
  selectedRoute: null,
  activeSelection: null,
  isMobile: false,
};
export const GateContext = createContext(defaultState);

function GateContextProvider(props) {
  const [isFirstTime, setFirstTime] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [activeSelection, setActiveSelection] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  return (
    <GateContext.Provider
      value={{
        firstTime: [isFirstTime, setFirstTime],
        selectRoute: [selectedRoute, setSelectedRoute],
        active: [activeSelection, setActiveSelection],
        mobileState: [isMobile, setIsMobile],
      }}
    >
      {props.children}
    </GateContext.Provider>
  );
}

export default GateContextProvider;
