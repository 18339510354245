import React from 'react';

import CrmForm from '@elements/crm/crm-form';

function CustomNewsletterForm({ globalData, pageContext }) {
  return (
    <CrmForm
      globalData={{
        ...globalData,
        newsletterData: globalData?.globalData?.newsletterForm,
      }}
      pageContext={pageContext}
    />
  );
}

export default CustomNewsletterForm;
